<template>

  <van-field
      :name="name"
      v-model="this.$props.value"
      v-show="false"
  >
  </van-field>
  <div style="text-align: center;margin-top: 5.33333vw">
    <van-image
        width="200"
        height="200"
        fit="center"
        :src="imgUrl"
    />
  </div>
  <van-row style="margin-top: 6.66667vw">
    <van-col span="6" v-for="(item, index) in allimgData" :key="index">
          <div class="img-item" :class="{active:item.bOn}"
               @click="checkImg(index)">
            <van-image
                width="64"
                height="64"
                fit="cover"
                :src="item.imgsrc"
            />
          </div>
      <div style="margin-left: 1.6vw" v-show="showText">{{item.val}}</div>
    </van-col>
  </van-row>

<!--  <div class="imgList">-->
<!--    <div class="img-item" v-for="(item, index) in allimgData" :class="{active:item.bOn}" :key="index"-->
<!--         @click="checkImg(index)">-->
<!--      <van-image-->
<!--          width="64"-->
<!--          height="64"-->
<!--          fit="cover"-->
<!--          :src="item.imgsrc"-->
<!--      />-->
<!--    </div>-->
<!--  </div>-->
</template>

<script>
export default {
  name: "ImageSelect",
  props: {
    //默认选取第一个
    selectFirst: {
      default: false,
      type: Boolean
    },
    canEmpty: {
      default: false,
      type: Boolean
    },
    showText: {
      default: true,
      type: Boolean
    },
    opData: {
      default: function () {
        return []
      },
      type: Array
    },
    name: {
      default: '',
      type: String
    },
    value: {
      default: '',
      type: String
    }
  },

  emits: ['update:value', 'onItemSelect'],

  setup() {
  },

  data() {
    return {
      arr: [],
      allimgData: [],
      imgUrl: ''
    }
  },

  computed: {},

  watch: {
    value: function (val) {
      let index = this.allimgData.indexOf(val);
      if (index !== -1) {
        this.allimgData[index].bOn = true;
      }
    },
  },

  created() {

  },

  mounted() {
    let mV = this.value;
    let select = -1;
    this.opData.map(function (value, index) {
      if (mV === value.val) {
        select = index;
      } else {
        value.bOn = false;
      }
    })
    this.allimgData = this.opData
    if (_.isEmpty(mV) && this.selectFirst) {
      this.checkImg(0);
    } else {
      this.checkImg(select);
    }
  },

  unmounted() {
  },

  methods: {
    checkImg(index) {
      if (index === -1) {
        return;
      }
      this.allimgData.map(function (value, i) {
        if (index !== i) {
          value.bOn = false;
        }
      });
      this.allimgData[index].bOn = this.canEmpty ? !this.allimgData[index].bOn : true;
      this.imgUrl = this.allimgData[index].bigimgsrc;
      this.$emit('update:value', this.allimgData[index].bOn ? this.allimgData[index].val : '');
      this.$emit('onItemSelect', this.allimgData[index], index);
    }
  }
}
</script>


<style scoped>
.imgList {
  width: 98%;
  margin: 10px 10px 10px 10px;
  background-color: #FFF;
  display: flex;
  flex-wrap: wrap;
}

.active {
  display: block;
  width: 64px;
  height: 64px;
  line-height: 0;
  font-size: 0;
  vertical-align: middle;
  border: 4px solid #417aef !important;
  -webkit-transform: rotate(0deg);
}

/*.imgList .active::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  border: 12px solid #2b89fd;
  border-top-color: transparent;
  border-left-color: transparent;
}
.imgList .active::after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  position: absolute;
  right: 4px;
  bottom: 5px;
  border: 1px solid #fff;
  border-top-color: transparent;
  border-left-color: transparent;
  transform: rotate(45deg);
  cursor: pointer;
}*/
.img-item {
  width: 64px;
  height: 64px;
  overflow: hidden;
  background-color: #FFFFFF;
  border: 4px solid #ffffff;
  margin: 4px;
  cursor: pointer;
}
</style>